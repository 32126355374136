import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CacheService } from './core/services/cache.service';
import { Globalization } from '@ionic-native/globalization/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { UpdateServiceService } from './core/services/update-service.service';
import { Platform } from '@ionic/angular';
import { CheckPointInPolygonService } from './core/services/check-point-in-polygon.service';
import { Point } from './shared/models/api/point';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  emailUser

  constructor(
    private cacheService:CacheService,
    private route:Router,
    private globalization: Globalization,
    private splashScreen: SplashScreen,
    private updateService:UpdateServiceService,
    private platform:Platform,
private checkPointService:CheckPointInPolygonService

  ) {

   // platform.ready().then(() => {



      this.updateService.getAppVersion()

    //}
  }

  ngOnInit(){

    this.checkPoint()

    this.splashScreen.hide();
    this.emailUser=this.cacheService.getEmailUser()

    console.log("email")
    console.log(this.emailUser)

    this.globalization.getPreferredLanguage()
  .then(res => {
    console.log(res)

    if(res.value=='it-IT'){
      this.cacheService.saveLanguage('it')
    }
    else{
      this.cacheService.saveLanguage('en')
    }
    //this.route.navigate(['../login'])
    if(this.emailUser.includes('@')){
      console.log("apri amp-list")
      this.route.navigate(['../amp-list'])
    }
    else{
      console.log("apri login")
      this.route.navigate(['../login'])
    }
  }
    )
  .catch(e => {
    console.log(e)
    this.route.navigate(['../login'])
  });


   

    

    
  }

  checkPoint(){
    let polygon1 = [new Point(0, 0),
      new Point(10, 0),
      new Point(10, 10),
      new Point(0, 10)];
       
let n = polygon1.length;
let p = new Point(20, 20);
if (this.checkPointService.isInside(polygon1, n, p))
{
document.write("Yes<br>");
}
else
{
document.write("No<br>");
}
p = new Point(5, 5);
if (this.checkPointService.isInside(polygon1, n, p))
{
document.write("Yes<br>");
}
else
{
document.write("No<br>");
}
let polygon2 = [new Point(0, 0),
new Point(5, 5), new Point(5, 0)];
p = new Point(3, 3);
n = polygon2.length;
if (this.checkPointService.isInside(polygon2, n, p))
{
document.write("Yes<br>");
console.log("true")
}
else
{
document.write("No<br>");
}
p = new Point(5, 1);
if (this.checkPointService.isInside(polygon2, n, p))
{
document.write("Yes<br>");
console.log("true")
}
else
{
document.write("No<br>");
}
p = new Point(8, 1);
if (this.checkPointService.isInside(polygon2, n, p))
{
document.write("Yes<br>");
console.log("true")
}
else
{
document.write("No<br>");
}
let polygon3 = [new Point(0, 0),
      new Point(10, 0),
      new Point(10, 10),
      new Point(0, 10)];
p = new Point(-1, 10);
n = polygon3.length;
if (this.checkPointService.isInside(polygon3, n, p))
{
document.write("Yes<br>");
console.log("true")
}
else
{
document.write("No<br>");
}       
  }
}
