import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  
  /*{
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },*/
  {
    path: '',
    loadChildren: () => import('./pages/shared/login/login.module').then( m => m.LoginPageModule)
    //loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  /*{
    path: '',
   loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
   //loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },*/
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then( m => m.MenuPageModule)
  },
  /*{
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },*/
  {
    path: 'percorsi',
    loadChildren: () => import('./pages/percorsi/percorsi.module').then( m => m.PercorsiPageModule)
  },
  {
    path: 'servizi',
    loadChildren: () => import('./pages/servizi/servizi.module').then( m => m.ServiziPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'altro',
    loadChildren: () => import('./pages/altro/altro.module').then( m => m.AltroPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/shared/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/shared/registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./pages/shared/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'segnalazioni',
    loadChildren: () => import('./pages/segnalazioni/segnalazioni.module').then( m => m.SegnalazioniPageModule)
  },
  {
    path: 'amp-list',
    loadChildren: () => import('./pages/amp-list/amp-list.module').then( m => m.AmpListPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, enableTracing: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
