import { ApiVerb } from "../app/core/helpers/api/apiVerb";
import { Api } from "../app/shared/models/api/api";
import { environment } from "./environment";


export class Apis {

  static APILogin: Api = {
    name: 'userLogin',
    url: environment.backendProduzione,
    method: ApiVerb.POST,
  }

  static APIGetUser: Api = {
    name: 'user/{email}',
    url: environment.backendProduzione,
    method: ApiVerb.GET,
  }

  static APIGetUserProfilo: Api = {
    name: 'profiliUtente/{email}',
    url: environment.backendProduzione,
    method: ApiVerb.GET,
  }

  static APILoginQRCode: Api = {
    name: 'sportcare-security-provider/auth/qrcode/login',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIPaymentMail: Api = {
    name: 'payment/make',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APISendDatiMonitoraggio: Api = {
    name: 'datiMonitoraggio/insert',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIGetUserQrcode: Api = {
    name: 'sportcare-security-provider/auth/qrcode/login',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetUserImage: Api = {
    name: 'anagrafica-manager/member/image/{id}?resize=true',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRegistration: Api = {
    name: 'userRegister',
    url: environment.backendProduzione,
    method: ApiVerb.POST,
  }

  static APIForgotPassword: Api = {
    name: 'forgotPassword',
    url: environment.backendProduzione,
    method: ApiVerb.POST,
  }

  static APIChangePassword: Api = {
    name: 'changePassword',
    url: environment.backendProduzione,
    method: ApiVerb.POST,
  }

  static APIUpdateAccount: Api = {
    name: 'updateAccount',
    url: environment.backendProduzione,
    method: ApiVerb.POST,
  }

  static APISendUserPosition: Api = {
    name: 'userPositions',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIDeleteUser: Api = {
    name: 'deleteUser',
    url: environment.backendProduzione,
    method: ApiVerb.POST,
  }

  // Servizi
  static APIGetServiziUser: Api = {
    name: 'serviziUser',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIGetServiziUserCampanella: Api = {
    name: 'serviziUserCampanella',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  

  static APIGetPrenotazioni: Api = {
    name: 'prenotazioni',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIGetProgram: Api = {
    //name: 'rgwc_event/event/program.json',
    name: 'program.json',
    url: environment.backendFTP,
    method: ApiVerb.GET,
  }

  static APIGetGenericFile: Api = {
    //name: 'rgwc_event/event/program.json',
    name: 'getVersion',//'{fileName}',
    url: environment.backendProduzione,
    method: ApiVerb.GET,
  }

  static APIGetSchedule: Api = {
    //name: 'rgwc_event/event/program.json',
    name: 'schedule.json',
    url: environment.backendFTP,
    method: ApiVerb.GET,
  }

  // Zone
  static APIGetZone: Api = {
    name: 'anagrafica-manager/featureType?start=0&size=100',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  // Notification

  static APIGetNotifications: Api = {
    name: 'notifications-manager/notifications/1111/deliveries?start=0&size=10000',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  // Profile

  static APIGetProfile: Api = {
    name: 'anagrafica-manager/member/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetUnion: Api = {
    name: 'anagrafica-manager/member/{id}/union ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIUpdateProfile: Api = {
    //name: 'anagrafica-manager/member/{id}',
    name: 'anagrafica-manager/member',
    url: environment.backend,
    method: ApiVerb.POST,
  }


  static APIRefeshToken: Api = {
    name: 'user/refresh',
    url: environment.backend,
    method: ApiVerb.POST,
  }

 
  static APILogout: Api = {
    name: 'user/logout',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  // Recupero password

  static APIRecuperoPassword: Api = {
    name: 'user/richiediRecuperoPwd',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  // Driver

  static APIRicercaDriverUtente: Api = {
    name: 'driver/ricercaDriver/utente',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIModificaDriver: Api = {
    name: 'driver/modificaDriver',
    url: environment.backend,
    method: ApiVerb.PUT,
  }

  static APIEliminaDriver: Api = {
    name: 'driver/eliminaDriver/utente',
    url: environment.backend,
    method: ApiVerb.DELETE,
  }

  static APIRicercaCorsaDriver: Api = {
    name: 'driver/ricercaCorsa/utente',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIRicercaPlanningDriver: Api = {
    name: 'driver/ricercaPlanning/utente',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIRicercaPrenotazionePlanning: Api = {
    name: 'prenotazioni/ricercaPrenotazione/planning/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetPrivacyPolicy: Api = {
    name: 'richiesta/ricercaDocumento/tipo/{codice}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIVidimaPrenotazione: Api = {
    name: 'prenotazioni/vidimaPrenotazione',
    url: environment.backend,
    method: ApiVerb.PUT,
  }





  //Utente

  static APIRicercaDipCliente: Api = {
    name: 'mobile/ricercaDipCliente/utente',
    url: environment.backend,
    method: ApiVerb.GET,
  }


  static APIModificaDipUtente: Api = {
    name: 'mobile/modificaDipCliente',
    url: environment.backend,
    method: ApiVerb.PUT,
  }

  static APIGetPrenotazione: Api = {
    name: 'prenotazioni/ricercaPrenotazione/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaPlanningAttuale: Api = {
    name: 'prenotazioni/ricercaPlanningAttuale/utente',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIInviaPrenotazione: Api = {
    name: 'prenotazioni/aggiungiCarnet',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIInviaPrenotazioneConsumo: Api = {
    name: 'prenotazioni/aggiungiPrenotazione',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIModificaPrenotazione: Api = {
    name: 'prenotazioni/modificaPrenotazione',
    url: environment.backend,
    method: ApiVerb.PUT,
  }


  static APIRicercaPlanning: Api = {
    name: 'prenotazioni/ricercaPrenotazione/utente',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIEliminaPrenotazione: Api = {
    name: 'prenotazioni/eliminaPrenotazione/{id}',
    url: environment.backend,
    method: ApiVerb.DELETE,
  }

  static APIDownloadPdf: Api = {
    name: 'Tesi_per_pdf.pdf',
    url: 'http://app.sportcare.tech/wsg2021/',//environment.backend,
    method: ApiVerb.GET,
  }

  // Linea

  static APIRicercaLinea: Api = {
    name: 'tipo/ricercaLinea/sedeOperativa/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaLineaById: Api = {
    //name: 'tipo/ricercaCorsa/linea/{id}/{isAndata}',
    name: 'mobile/ricercaLinea/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

// COSTO


static APIGetCostoByLinea: Api = {
  name: 'mobile/calcolaCosto/linea/{id}/{isAbbonamento}',
  url: environment.backend,
  method: ApiVerb.GET,
}

  // Corsa
  static APIRicercaCorsaByLinea: Api = {
    name: 'tipo/ricercaCorsa/linea/{idLinea}/sedeOperativa/{idSedeOperativa}/{isAndata}/{data}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaCorsaByLineaBySedeOperativa: Api = {
    name: 'tipo/ricercaCorsa/linea/{idLinea}/sedeOperativa/{idSedeOperativa}/{isAndata}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

    // Fermate
   
    static APIRicercaFermateByCorsa: Api = {
      name: 'tipo/ricercaFermataDirettriceCorsa/corsa/{​​​​​idCorsa}/sedeOperativa/{idSedeOperativa}/{isAndata}',
      url: environment.backend,
      method: ApiVerb.GET,
    }

    static APIGetFermata: Api = {
      name: 'tipo/ricercaFermataDirettriceCorsa/fermataDirettriceCorsa/{idFermataDirettriceCorsa}/sedeOperativa/{idSedeOperativa}/{isAndata}',
      url: environment.backend,
      method: ApiVerb.GET,
    }

    

   // Sede Operatiiva

   static APIRicercaSede: Api = {
    name: 'tipo/ricercaSedeOperativa/utente',
    url: environment.backend,
    method: ApiVerb.GET,
  }

   // Servizio

   static APIRicercaMensileSuccessivo: Api = {
    name: 'mobile/ricercaMensileSuccessivo/utente',
    url: environment.backend,
    method: ApiVerb.GET,
  }

   static APIVerificaServizio: Api = {
    name: 'mobile/verificaServizio',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaServizio: Api = {
    name: 'mobile/ricercaServizio/utente',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIAggiungiServizio: Api = {
    name: 'mobile/aggiungiServizio',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIModificaServizio: Api = {
    name: 'mobile/modificaServizio',
    url: environment.backend,
    method: ApiVerb.PUT,
  }

  static APIRicercaPostoByCorsa: Api = {
    name: '/mobile/ricercaPosto/corsa/{id}/{rinnovo}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaAllestimento: Api = {
    name: '/tipo/ricercaAllestimento/taglia/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaPostoByData: Api = {
    name: 'prenotazioni/ricercaPosto/corsa/{id}/{data}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaPostoByFermataByData: Api = {
    name: 'prenotazioni/ricercaPosto/fermataDirettriceCorsa/{idFermataDirettriceCorsa}/sedeOperativa/{idSedeOperativa}/{data}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIRicercaPostoByFermata: Api = {
    name: 'mobile/ricercaPosto/fermataDirettriceCorsa/{idFermataDirettriceCorsa}/sedeOperativa/{idSedeOperativa}/{isRinnovo}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  


  static APIEliminaServizio: Api = {
    name: 'mobile/eliminaServizio/{id}',
    url: environment.backend,
    method: ApiVerb.DELETE,
  }

  static APIVerificaConsumo: Api = {
    name: 'mobile/verificaConsumo',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  //password
 
  static APIRichiestaRecuperaPwd: Api = {
    name: 'user/richiediRecuperoPwd',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIAggiornaPwd: Api = {
    name: 'user/aggiornaPwd',
    url: environment.backend,
    method: ApiVerb.PUT,
  }

  static APIVerificaOtl: Api = {
    name: 'user/verificaOtl/{otl}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIEliminaUtente: Api = {
    name: 'mobile/eliminaDipCliente/utente',
    url: environment.backend,
    method: ApiVerb.DELETE,
  }


  /*//dipendente

  static APIRicercaDipCliente: Api = {
    name: 'mobile/ricercaDipCliente/utente/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  

  static APImodificaDipCliente: Api = {
    name: 'mobile/modificaDipCliente',
    url: environment.backend,
    method: ApiVerb.PUT,
  }*/


  //Utility

  static APIlistaGiorno: Api = {
    name: 'richiesta/listaGiorno',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIlistaSede: Api = {
    name: 'richiesta/ricercaSedeOperativa/aziendaCliente/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIrichiestaManifestaInteresse: Api = {
    name: 'richiesta/manifestaInteresse',
    url: environment.backend,
    method: ApiVerb.POST,
  }



  /**************************** */

  static APIGetItinerari: Api = {
    name: 'itinerari/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetBoe: Api = {
    name: 'boe/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetBoePrenotate: Api = {
    name: 'boe_prenotate',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetBoePrenotateDAY: Api = {
    name: 'day_boe_prenotate',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIGetBoePrenotateDAYCampanella: Api = {
    name: 'day_boe_prenotate_campanella',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetBoePrenotateDAYTremiti: Api = {
    name: 'boe_prenotate_tremiti',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIGetItinerario: Api = {
    name: 'itinerario/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetAmp: Api = {
    name: 'amp/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetPerimetroAmp: Api = {
    name: 'perimetro_amp/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetZoneAmp: Api = {
    name: 'zone_amp/{id} ',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetSegnalazioni: Api = {
    name: 'segnalazioni/user',
    url: environment.backend,
    method: ApiVerb.POST,
  }

  static APIGetImmagini: Api = {
    name: 'getImmagini/{id}',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetProfiliUtente: Api = {
    name: 'profiliUtente',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  static APIGetAMPS: Api = {
    name: 'amps',
    url: environment.backend,
    method: ApiVerb.GET,
  }

  



}