import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor() { }

  public saveEmailUser(email ) {

    localStorage.setItem("email",email);
  }

  public getEmailUser() {

    var email_user = localStorage.getItem('email');

    return email_user;
  }

  public saveLanguage(language ) {

    localStorage.setItem("lang",language);
  }

  public getLanguage() {

    var language = localStorage.getItem('lang');
    return language?language:'it';
  }

  public saveAmpId(id ) {

    localStorage.setItem("id",id);
  }

  public getAmpId() {

    var ampId = localStorage.getItem('id');
    return ampId;
  }

  public saveAmpName(ampName ) {

    localStorage.setItem("ampName",ampName);
  }

  public getAmpName() {

    var ampName = localStorage.getItem('ampName');
    return ampName;
  }


  public saveAmpTest(ampTest ) {

    localStorage.setItem("ampTest",ampTest);
  }

  public getAmpTest() {

    var ampTest = localStorage.getItem('ampTest');
    return ampTest;
  }

  public setPoiNotification(ampName ) {

    localStorage.setItem("poiNotification",ampName);
  }

  public getPoiNotification() {

    var ampName = localStorage.getItem('poiNotification');
    return ampName;
  }
}
