import { ThisReceiver } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { relativeTimeThreshold } from 'moment';
import { Observable } from 'rxjs/internal/Observable';
import { AlertService } from 'src/app/core/services/alert.service';
import { ApiService } from 'src/app/core/services/api.service';
import { CacheService } from 'src/app/core/services/cache.service';
import { Registration } from '../../models/registration';

@Component({
  selector: 'app-impostazioni',
  templateUrl: './impostazioni.component.html',
  styleUrls: ['./impostazioni.component.scss'],
})
export class ImpostazioniComponent implements OnInit {
  confermaPassword: string;
  listaProfili;
  impostazioniForm: FormGroup;

  segmentModel: string = 'acquistati';

  public nome: string;
  public cognome: string;
  public nome_utente: string;
  public email: string;
  public password: string;
  public registrati: string;
  public profili: string;

  public title
  public cambia_password
  public salva 
  public elimina_account
  public cancella_utenza_title
  public cancella_utenza_msg 
  public cancella_utenza_msg_doppio 
  public modifica_password_title
  public modifica_password_conferma_password 

  public foodList = [];//= [1, 7];
  profiliLista=[]

  check=true

  constructor(
    private modalCtr: ModalController,

    public fb: FormBuilder,
    private alertService: AlertService,
    private route: Router,
    private apiService: ApiService,
    private cacheService: CacheService,
    private _translate: TranslateService,
    private alertController: AlertController
  ) {}

  ngOnInit() {
    this.impostazioniForm = this.fb.group({
      //user_email:['',Validators.required ],

      username: ['', Validators.required],
      //vecchia_password:['',Validators.required ],
      //nuova_password:['',Validators.required ],
      //ripeti_password:['',Validators.required ],
      profili: ['',Validators.required],
    });
  }

  ionViewDidEnter(): void {
    this._translate.use(this.cacheService.getLanguage());
    //this._translate.use('en');
    this._initialiseTranslation();

    this.check=this.getCheck()

    this.getUser();
    //this.getUserProfili();
    this.getProfili();
  }

  getCheck(){
    
    return this.cacheService.getPoiNotification()=== 'true'
  }

  async close() {
    const closeModal: string = 'Modal Closed';
    await this.modalCtr.dismiss(closeModal);
  }

  _initialiseTranslation(): void {
    this._translate.get('register_page.nome').subscribe((res: string) => {
      this.nome = res;
    });
    this._translate.get('register_page.cognome').subscribe((res: string) => {
      this.cognome = res;
    });
    this._translate
      .get('register_page.nome_utente')
      .subscribe((res: string) => {
        this.nome_utente = res;
      });
    this._translate.get('register_page.email').subscribe((res: string) => {
      this.email = res;
    });
    this._translate.get('register_page.password').subscribe((res: string) => {
      this.password = res;
    });
    this._translate.get('register_page.registrati').subscribe((res: string) => {
      this.registrati = res;
    });
    this._translate.get('register_page.profili').subscribe((res: string) => {
      this.profili = res;
    });


    
  


  this._translate.get('impostazioni_page.title').subscribe((res: string) => {
    this.title = res;
  });

  this._translate.get('impostazioni_page.cambia_password').subscribe((res: string) => {
    this.cambia_password = res;
  });

  this._translate.get('impostazioni_page.salva').subscribe((res: string) => {
    this.salva = res;
  });

  this._translate.get('impostazioni_page.elimina_account').subscribe((res: string) => {
    this.elimina_account = res;
  });

  this._translate.get('impostazioni_page.cancella_utenza_title').subscribe((res: string) => {
    this.cancella_utenza_title = res;
  });

  this._translate.get('impostazioni_page.cancella_utenza_msg').subscribe((res: string) => {
    this.cancella_utenza_msg = res;
  });

  this._translate.get('impostazioni_page.cancella_utenza_msg_doppio').subscribe((res: string) => {
    this.cancella_utenza_msg_doppio = res;
  });

  this._translate.get('impostazioni_page.modifica_password_title').subscribe((res: string) => {
    this.modifica_password_title = res;
  });

  this._translate.get('impostazioni_page.modifica_password_conferma_password').subscribe((res: string) => {
    this.modifica_password_conferma_password = res;
  });


  }

  register() {
    //this.loading.show()

    let registration: Registration = new Registration(
      this.impostazioniForm.value
    );

    //registration.user_email=this.impostazioniForm.get('email').value
    registration.device_serial = '';

    console.log(registration);

    console.log(this.profiliLista)


    //registration.profile=this.profiliLista

    this.apiService.updateAccount(this.cacheService.getEmailUser(),registration.username, this.profiliLista).subscribe(
     response => {
      // this.loading.close()
      // console.log("Response: "+response)

if(response){
  
   this.alertService.presentAlert("Profilo Aggiornato")
 
}
       

     }, (error) => {
    
       this.alertService.presentAlert("Impossibile contattare il server")
       console.log(JSON.stringify(error))
       
     }
   )
  }

  async presentAlertConfirm(message) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Attenzione',
      message: message,
      buttons: [
        /* {
         text: 'Cancel',
         role: 'cancel',
         cssClass: 'secondary',
         handler: (blah) => {
           console.log('Confirm Cancel: blah');
         }
       },*/ {
          text: 'OK',
          handler: () => {
            console.log('Confirm Okay');
            this.route.navigate(['']);
          },
        },
      ],
    });

    await alert.present();
  }

  getProfili() {
    this.apiService.getProfiliUtente().subscribe((res) => {
      this.listaProfili = res;
      this.getUserProfili()
     
    });
  }

  getUser() {
    this.apiService
      .getUser(this.cacheService.getEmailUser())
      .subscribe((res) => {
        this.impostazioniForm.get('username').setValue(res[0].username);
      });
  }

  getUserProfili() {
    this.apiService
      .getUserProfile(this.cacheService.getEmailUser())
      .subscribe((res) => {
        res.forEach(element=>{
          this.profiliLista.push(element.user_profile_profile_id)
        })
      });
  }


  async apriModificaPassword() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.modifica_password_title,
      inputs: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Password',
          cssClass: 'specialClass',
          attributes: {
            maxlength: 8,
            inputmode: 'text',
          },
        },
        {
          name: 'confermPassword',
          type: 'password',
          placeholder: this.modifica_password_conferma_password,
          cssClass: 'specialClass',
          attributes: {
            maxlength: 8,
            inputmode: 'text',
          },
        },
      ],
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'Modifica',
          handler: (alertData) => {
            console.log(alertData.password);
            this.password = alertData.password;
            this.confermaPassword = alertData.confermPassword;

            if (
              this.password.normalize() === this.confermaPassword.normalize()
                ? true
                : false
            ) {
              this.changePassword(this.password);
            } else {
              this.alertService.presentAlert('Password non uguali');
            }
          },
        },
      ],
    });

    await alert.present();
  }

  changePassword(password) {
    this.apiService
      .changePassword(this.cacheService.getEmailUser(), password)
      .subscribe((res) => {
        //this.impostazioniForm.get('username').setValue(res[0].username)
        this.alertService.presentAlert('Password cambiata correttamente');
      });
  }





  async confermaEliminaUtente() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.cancella_utenza_title,
      message:
        this.cancella_utenza_msg,
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'OK',
          handler: () => {
            console.log('Cancellazione utenza');
            this.confermaEliminaUtenteDoppio();
          },
        },
      ],
    });

    await alert.present();
  }

  async confermaEliminaUtenteDoppio() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: this.cancella_utenza_title,
      message:
        this.cancella_utenza_msg_doppio,
      buttons: [
        {
          text: 'Annulla',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {},
        },
        {
          text: 'OK',
          handler: () => {
            console.log('Cancellazione utenza');
            this.eliminaUtente();
          },
        },
      ],
    });

    await alert.present();
  }

  async eliminaUtente() {
    this.apiService
      .deleteUser(this.cacheService.getEmailUser())
      .subscribe((res) => {
        this.close();
        this.cacheService.saveEmailUser(null);
        this.route.navigate([''], { replaceUrl: true });
      });
  }



  compareWith(o1, o2) {
    if(!o1 || !o2) {
      return o1 === o2;
    }

    if(Array.isArray(o2)) {
      return o2.some((o) => o === o1);
    }

    return o1 === o2;
  }

  handleChange(ev) {
    this.profiliLista = ev.target.value;
  }




  checkboxClick(e){
    console.log("event",e)
    var statement = true;

    this.cacheService.setPoiNotification(e.detail.checked)
    
  }


  changeEvent(event){
    console.log(event)
    this.segmentModel=event.detail.value
  }
}
