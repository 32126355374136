import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientService } from './core/services/http-client.service';
import { PayPal } from '@ionic-native/paypal/ngx';

import { Camera } from '@ionic-native/Camera/ngx';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { NgxIonicImageViewerModule } from 'ngx-ionic-image-viewer';
import { Globalization } from '@ionic-native/globalization/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';

import { AppVersion } from '@ionic-native/app-version/ngx'; 
import { Market } from '@ionic-native/market/ngx';
import { LaunchNavigator } from '@ionic-native/launch-navigator/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { FormBuilder } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ImpostazioniComponent } from './shared/components/impostazioni/impostazioni.component';
import { SharedModule } from './shared/shared.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
  }

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule,
    SharedModule,
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
     IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,NgxIonicImageViewerModule],
  providers: [
    HttpClientService,
    PayPal,
    CallNumber,
    Camera,
    SplashScreen,
    AppVersion,
    Market,
    Geolocation,
    LaunchNavigator,
    Globalization,
    AndroidPermissions,
    FormBuilder,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
