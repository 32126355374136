import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiVerbUtils } from '../helpers/api/apiVerbUtils';
import { Api } from 'src/app/shared/models/api/api';
import { ApiVerb } from '../helpers/api/apiVerb';
import { environment } from 'src/environments/environment';


@Injectable()
export class HttpClientService {

 
  constructor(
    private httpClient: HttpClient,
    //private tokenService:TokenService
    ) { }

  public send(api: Api, authenticated:boolean, body: any = null, headers?: HttpHeaders, params?: any, responseType?: string): Observable<any> {


    let token = ""
    if(authenticated){
       //token = this.tokenService.getAccessTokenUser()
      if (!token) {
       // token = "";
      }
    }

    if (headers == null) {
     if(authenticated){
      //headers = new HttpHeaders({ 'Accept': 'application/json', 'x-auth-token':environment.token });
      headers = new HttpHeaders({ 'Accept': 'application/json'}); 
    }
     else{
      headers = new HttpHeaders({ 'Accept': 'application/json'});
      //headers = new HttpHeaders({'Accept': 'application/pdf'});
     }
     
    }

    let url = ApiVerbUtils.Get(api);

    //console.log("aaaa"+JSON.stringify(api))
    //console.log("URL: "+url)

    switch (api.method) {
      case ApiVerb.GET:
        return this.get(url, { headers: headers, params: params, responseType: responseType});
      case ApiVerb.POST:
        return this.post(url, body, { headers: headers, params: params });
      case ApiVerb.PUT:
        console.log("PUT BODY: " + JSON.stringify(body));
        return this.put(url, body, { headers: headers, params: params });
        case ApiVerb.DELETE:
            return this.delete(url, body,);
      default:
        break;
    }

  }

  public sendWithUrlParam(api: Api, authenticated:boolean, urlParams?: any, body: any = null, headers?: HttpHeaders, params?: any, responseType?: string): Observable<any> {

    var  token = ""
    if(authenticated){
    // token = this.tokenService.getAccessTokenUser()
    if (!token) {
      //token = "";
    }
  }

    if (headers == null) {
      if(authenticated){
     //headers = new HttpHeaders({ 'Accept': 'application/json', 'x-auth-token':environment.token });
      //headers = new HttpHeaders({  'x-auth-token':environment.token });
      headers = new HttpHeaders({ 'Accept': 'application/json'});
      }
      else{
        headers = new HttpHeaders({ 'Accept': 'application/json' });
      }
    }
  

    let url = ApiVerbUtils.Get(api);
    if (urlParams != null) {
      url = this.format(url, urlParams);
    }

    switch (api.method) {
      case ApiVerb.GET:
        return this.get(url, { headers: headers, params: params, responseType: responseType});
      case ApiVerb.POST:
        return this.post(url, body, { headers: headers, params: params });
      case ApiVerb.PUT:
        return this.put(url, body, { headers: headers, params: params });
        case ApiVerb.DELETE:
            return this.delete(url, { headers: headers, params: params, responseType: responseType});
      default:
        break;
    }

  }

  public sendWithUrlParamAppend(api: Api, authenticated:boolean, urlParams?: any, body: any = null, headers?: HttpHeaders, params?: any, responseType?: string): Observable<any> {

    var  token = ""
    if(authenticated){
   //  token = this.tokenService.getAccessTokenUser()
    if (!token) {
      token = "";
    }
  }

    if (headers == null) {
      if(authenticated){
     // headers = new HttpHeaders({ 'Accept': 'application/json', 'x-auth-token':environment.token});
      headers = new HttpHeaders({ 'Accept': 'application/json'});  
    }
      else{
        headers = new HttpHeaders({ 'Accept': 'application/json' });
      }
    }
  

    let url = ApiVerbUtils.Get(api);
    if (urlParams != null) {
      url = this.formatAppend(url, urlParams);
    }


  

    switch (api.method) {
      case ApiVerb.GET:
        return this.get(url, { headers: headers, params: params, responseType: responseType});
      case ApiVerb.POST:
        return this.post(url, body, { headers: headers, params: params });
      case ApiVerb.PUT:
        return this.put(url, body, { headers: headers, params: params });
        case ApiVerb.DELETE:
            return this.delete(url, { headers: headers, params: params, responseType: responseType});
      default:
        break;
    }

  }

  


  private format(urlWithParameters: string, params: any): string {
    for (let param in params) {
      if (params.hasOwnProperty(param)) {
        urlWithParameters = urlWithParameters.replace("{" + param + "}", params[param])
      }
    }
    return urlWithParameters;
  }

  private formatAppend(urlWithParameters: string, params: any): string {
   
    let index=0
    for (let param in params) {
      if (params.hasOwnProperty(param)) {
       if(index==0){
        urlWithParameters += "?"+param+"="+params[param]
       }
       else{
        urlWithParameters += "&"+param+"="+params[param]
       }
       index++
      }
    }
    return urlWithParameters;
  }

  private get(url: string, options: any): Observable<any> {
    return this.httpClient.get(url, options);
  }

  private post(url: string, body: any, options: any): Observable<any> {
    return this.httpClient.post(url, body, options);
  }
 
  private put(url: string, body: any, options: any): Observable<any> {
    return this.httpClient.put(url, body, options);
  }

  private delete(url: string, options: any): Observable<any> {
    return this.httpClient.delete(url, options);
  }


}