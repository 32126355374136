// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.




export const environment = {
  production: false,
  backend:"https://api.dev.blue-discovery.it/public/index.php/api/v1/",
  backendProduzione:"https://api.blue-discovery.it/public/index.php/api/v1/",
  //backendFTP:"http://app.sportcare.tech/wsg2021/",
  backendFTP:"https://api.blue-discovery.it/public/",
  firebaseConfig : {
    apiKey: "AIzaSyBQBNSVYQyiTpiFpfrG3zuD6j5NYJ7hEIE",//  AIzaSyDxnu69PurRdZlc7B44poak2Ruk2RK8QbU",//"AIzaSyBh1Gy7pcHrfsIOHvX94XfsVJ-G9SNuWGY",
    authDomain: "wsg-ionic.firebaseapp.com",
    projectId: "wsg-ionic",
    storageBucket: "wsg-ionic.appspot.com",
    messagingSenderId: "825328840144",
    appId: "1:825328840144:web:bfe1db1db65df820725dd5",
    measurementId: "G-0W9RZ4QLBC"
  },
  password:"public_password",
  token:"eyJhbGciOiJIUzUxMiJ9.eyJqdGkiOiIxMjM0Iiwic3ViIjoidXNlckBleGFtcGxlLmNvbSIsImlhdCI6MTUxOTEzODQzMSwiUk9MRVMiOlsiUk9MRV9BRE1JTiJdfQ.81mImxo-D71jA5xbSvMCc7dXVaFI4DMBbtDmbdJrhYvCwXG4clsDSIeKiOtMKrd3RBmOJAnWQicG5aynzrXjnA",
  error_message:"Attention! It seems that something has gone wrong.",
  profilo_aggiornato:"Profile updated!",
  titolo_profilo_warning:"Good",

  link_immagini_lista_amp:"https://api.dev.blue-discovery.it/storage/images/"
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
