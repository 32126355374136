export class Point {
    x: number;
        y: number;
       


    public constructor(x,y)
    {
        this.x = x;
        this.y = y;
    }
}