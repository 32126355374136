import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';

import { AppVersionResponse } from 'src/app/shared/models/appVersionResponse';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { ApiService } from './api.service';
import { Globalization } from '@ionic-native/globalization/ngx';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class UpdateServiceService {
  appVersionResponse: AppVersionResponse;

  close: string;

  private language;
  constructor(
    private apiService: ApiService,
    private alertCtrl: AlertController,
    private appVersion: AppVersion,
    private platform: Platform,
    private market: Market,
    private globalization: Globalization,
    private _translate: TranslateService
  ) {}

  _initialiseTranslation(): void {
    this._translate.get('aggiornamento_page.close').subscribe((res: string) => {
      this.close = res;
    });
  }

  getAppVersion() {
    this.apiService.getGenericJson('app_version.json').subscribe(
      async (response) => {
        if (response) {
         this.globalization.getPreferredLanguage().then(async (res) => {
            console.log(res);

            if (res.value == 'it-IT') {
              this.language = 'it';
            } else {
              this.language = 'en';
            }

           

            this._translate.use(this.language);
            this._initialiseTranslation();

            this.appVersionResponse = response;

            if (!this.appVersionResponse.enabled) {
              this.presentAlert(
                this.language == 'en'
                  ? this.appVersionResponse.title
                  : this.appVersionResponse.title_it,
                this.language == 'en'
                  ? this.appVersionResponse.msg
                  : this.appVersionResponse.msg_it,
                  '',
                  false
              );
            } else {
              const versionNumber = await this.appVersion.getVersionNumber();
              const serverVersion = this.appVersionResponse.current;

      
              if ((versionNumber != serverVersion) && this.appVersionResponse.visualizzaMessaggio) {
                if (this.appVersionResponse.forced) {
                  this.presentAlert(
                    this.language == 'en'
                      ? this.appVersionResponse.majorMsg.title
                      : this.appVersionResponse.majorMsg.title_it,
                    this.language == 'en'
                      ? this.appVersionResponse.majorMsg.msg
                      : this.appVersionResponse.majorMsg.msg_it,
                    this.language == 'en'
                      ? this.appVersionResponse.majorMsg.btn
                      : this.appVersionResponse.majorMsg.btn_it,
                      false
                  );
                } else {
                  this.presentAlert(
                    this.language == 'en'
                      ? this.appVersionResponse.minorMsg.title
                      : this.appVersionResponse.minorMsg.title_it,
                    this.language == 'en'
                      ? this.appVersionResponse.minorMsg.msg
                      : this.appVersionResponse.minorMsg.msg_it,
                    this.language == 'en'
                      ? this.appVersionResponse.minorMsg.btn
                      : this.appVersionResponse.minorMsg.btn_it,
                    true
                  );
                }
              }

              /*const versionNumber = await this.appVersion.getVersionNumber();
              const splittedVersion = versionNumber.split('.');
              const serverVersion = this.appVersionResponse.current.split('.');

              if (serverVersion[0] > splittedVersion[0]) {
                this.presentAlert(
                  this.language == 'en'
                    ? this.appVersionResponse.majorMsg.title
                    : this.appVersionResponse.majorMsg.title_it,
                  this.language == 'en'
                    ? this.appVersionResponse.majorMsg.msg
                    : this.appVersionResponse.majorMsg.msg_it,
                  this.language == 'en'
                  ?
                  this.appVersionResponse.majorMsg.btn
                  : this.appVersionResponse.majorMsg.btn_it,
                );
              } else if (serverVersion[1] > splittedVersion[1]) {
                this.presentAlert(
                  this.language == 'en'
                    ? this.appVersionResponse.majorMsg.title
                    : this.appVersionResponse.majorMsg.title_it,
                  this.language == 'en'
                    ? this.appVersionResponse.majorMsg.msg
                    : this.appVersionResponse.majorMsg.msg_it,
                  this.language == 'en'
                    ?
                    this.appVersionResponse.majorMsg.btn
                    : this.appVersionResponse.majorMsg.btn_it,
                );
              } else if (serverVersion[2] > splittedVersion[2]) {
                this.presentAlert(
                  this.language == 'en'
                    ? this.appVersionResponse.minorMsg.title
                    : this.appVersionResponse.minorMsg.title_it,
                  this.language == 'en'
                    ? this.appVersionResponse.minorMsg.msg
                    : this.appVersionResponse.minorMsg.msg_it,
                    this.language == 'en'
                    ?
                    this.appVersionResponse.minorMsg.btn
                    : this.appVersionResponse.minorMsg.btn_it,
                  true
                );
              }*/
            }
          });
        }
      },
      (error) => {
        console.error(error);
      }
    );
  }

  openAppStore() {
    if (this.platform.is('android')) {
      this.market.open('eu.bluethread.bluediscovery');
    } else {
      this.market.open('1576167757');
    }
  }

  async presentAlert(header, message, buttonText = '', allowClose) {
    const buttons = [];
console.log("allow close",allowClose)
    if (allowClose) {
      buttons.push({
        text: 'Annulla',//this.close,
        role: 'cancel',
      });
    }
    if (buttonText != '') {
      buttons.push({
        text: buttonText,
        handler: () => {
          this.openAppStore();
        },
      });
    }

    console.log(buttons)

    const alert = await this.alertCtrl.create({
      header,
      message,
      buttons,
      backdropDismiss: allowClose,
    });

    await alert.present();
  }
}
