import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { ImpostazioniComponent } from "./components/impostazioni/impostazioni.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";




@NgModule({

  declarations: [ImpostazioniComponent],

  imports: [
    CommonModule,
    FormsModule,
   
    ReactiveFormsModule,
],

  exports: [ImpostazioniComponent]

})

export class SharedModule {}